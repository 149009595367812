// MeetingsManagerStandard

.meetings-manager-standard {
    width: 100%;
}

// MeetingsManagerExternal

.meetings-manager-iframe {
    height: calc(100vh - 110px);
    width: 100%;
    border: none;

    &.no-breadcrumb {
        height: calc(100vh - 68px);
    }
}
