.date-col {
    width: 150px;
}

.participants-col {
    width: 150px;
}

.join-button-col {
    width: 100px;
}

.download-button-col {
    width: 200px;
}

.delete-button {
    background-color: $errorMessageBgColor !important;
    border-radius: 3px !important;
}

.row-not-downloaded {
    background-color: #fcf8e3 !important;
}

.row-downloaded {
    background-color: #dff0d8 !important;
}

.table-message {
    margin-left: -7px;
}

.recording-info-text {
    padding-top: 10px;
}
