$adviserStateReadyColor: #00cc66;
$adviserStateBusyColor: #ff1432;

@media (max-width: 1024px) {
    .adviser-state-widget {
        color: #212121 !important;
        margin-top: 0 !important;
    }

    .adviser-state-color-mobile {
        &.ready {
            color: $adviserStateReadyColor;
            filter: drop-shadow(0px 0px 2px $primaryLightestColor);
        }
        &.busy {
            color: $adviserStateBusyColor;
            filter: drop-shadow(0px 0px 2px $primaryLightestColor);
        }
    }
}

@media (max-width: 640px) {
    div.adviser-state-widget .p-buttonset > .p-button:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    div.adviser-state-widget .p-buttonset > .p-button:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    div.adviser-state-widget .p-buttonset > .p-button {
        display: inline-block;
        border-radius: 3px;
    }
}

.adviser-state-widget {
    position: relative;
    //height: 50px;
    //margin-top: -8px;
    border-radius: 5px;
    cursor: default;
    white-space: nowrap;
    color: $primaryLightestColor;
    text-align: center;

    .adviser-state-widget-header {
        font-size: 11px;
        font-style: italic;
        margin-bottom: 3px;
    }

    .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
        background-color: #ffffff;
    }

    .p-buttonset.p-button-secondary > .p-button {
        //background-color: $primaryLightestColor;

        &:not(.p-highlight) {
            color: $primaryColor;
        }
    }

    .ready {
        .p-highlight {
            border-color: #C6C7CD;
            background-color: $adviserStateReadyColor !important;
        }
    }
    .busy {
        .p-highlight {
            background-color: $adviserStateBusyColor !important;
        }
    }
}

.call-manager {
    .meetings-manager-iframe {
        height: calc(100vh - 225px);

        &.no-breadcrumb {
            height: calc(100vh - 183px);
        }
    }
}

.accept-call-dialog {
    width: 500px;
    max-width: 100%;

    .accept-call-dialog-clientInfoTable {
        border: none;
        td {
            padding: 5px 5px 5px 0;

            &.clientInfo-label {
                font-weight: bold;
            }
        }
    }
}

.accept-call-loading-wrapper {
    height: 150px;
}

.open-calls-dialog {
    width: 500px;
    max-width: 100%;
}
