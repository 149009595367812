/* Sidebar */
$sidebarLogoBgColor: $sidebarBgColor;
$sidebarBgColor: $sidebarBgColor;
$darkSidebarBgColor: $sidebarBgColor;
$horizontalMenuBgColor: #080a10;
$horizontalDarkMenuBgColor: #0a1118;

/* Primary */
$primaryColor: $primaryColor;
$primaryDarkColor: $primaryDarkColor;
$primaryLightColor: $primaryLightColor;
$primaryLightestColor: $primaryLightestColor;
$primaryTextColor: $primaryTextColor;

/* Accent */
$accentColor: $themeColor;
$accentLightColor: $themeColorLight;
$accentTextColor: $accentTextColor;

/* Topbar */
$topbarTextColor: $primaryLightestColor;
$topbarIconColor: $primaryLightestColor;

/* Submenu */
$submenuBgColor: rgba(255, 255, 255, 0.2);
$darkSubmenuBgColor: rgba(255, 255, 255, 0.2);
$horizontalSubmenuBgColor: #2b3d4c;
$horizontalDarkSubmenuBgColor: #0e161e;
$horizontalSubmenuitemHoverBgColor: rgba(255, 255, 255, 0.4);
$horizontalSubmenuitemDarkHoverBgColor: rgba(255, 255, 255, 0.4);
$horizontalSubmenuitemDarkHoverTextColor: #ffffff;
$horizontalMenuActiveTextColor: #ffffff;
$horizontalMenuActiveHoverTextColor: #ffffff;
$horizontalDarkMenuActiveTextColor: #ffffff;
$horizontalDarkMenuActiveHoverTextColor: #ffffff;

/* Default MenuItem */
$menuitemTextColor: #ffffff;
$menuitemIconTextColor: #ffffff;

/* Hover MenuItem */
$menuitemHoverBgColor: #rgba(255, 255, 255, 0.4);
$menuitemHoverTextColor: #ffffff;
$menuitemHoverIconTextColor: #ffffff;

/* Active MenuItem */
$menuitemActiveBgColor: $themeColor;
$menuitemActiveTextColor: $primaryTextColor;
$menuitemActiveIconTextColor: $primaryTextColor;
$subMenuitemActiveTextColor: $themeColorLight;
$subMenuitemActiveIconTextColor: $themeColorLight;

/* Dark Default MenuItem */
$darkMenuitemTextColor: #dee0e3;
$darkMenuitemIconTextColor: #dee0e3;

/* Dark Hover MenuItem */
$darkMenuitemHoverBgColor: #rgba(255, 255, 255, 0.4);
$darkMenuitemHoverTextColor: #ffffff;
$darkMenuitemHoverIconTextColor: #ffffff;

/* Dark Active MenuItem */
$darkMenuitemActiveBgColor: $themeColor;
$darkMenuitemActiveTextColor: $primaryTextColor;
$darkMenuitemActiveIconTextColor: $primaryTextColor;
$darksubMenuitemActiveTextColor: $themeColorLight;
$darksubMenuitemActiveIconTextColor: $themeColorLight;

$unbluSecondaryButtonColor: #26337C;
$unbluSecondaryHoverButtonColor: #006CBD;
$unbluPrimaryButtonColor: $themeColor;
$unbluPrimaryHoverButtonColor: #E54C05;

@import '../../serenity/sass/layout/layout';

/*
  adiacom serenity customization
 */

// menu mode toggle icon
.sidebar-anchor {
    margin-top: 15px !important;
}

// override for account circle right
.profile-name {
    display: inline-block !important;
    margin-left: 0 !important;
    margin-right: 6px !important;
    vertical-align: middle !important;
    margin-top: -24px !important;
}

@media (max-width: $mobileBreakpoint) {
    .profile-name {
        display: inline-block !important;
        margin-left: 0 !important;
        vertical-align: middle !important;
        margin-top: -0 !important;
    }
}

// dropdown desktop mode
@media (max-width: $mobileBreakpoint) {
    .topbar-desktop-dropdown {
        display: none;
    }
}

.topbar-desktop-dropdown-arrow {
    margin-left: -8px;
}

// icon for mobile menu
@media (min-width: $mobileBreakpoint) {
    .topbar-icon.mobile-only {
        display: none;
    }
}

// transparent if submenu item but no subitem selected
.open-submenu-inactive-item {
    > a {
        background-color: $themeColorTransparent !important;
    }
}

// fix active menu item border radius
.layout-wrapper .layout-sidebar .layout-menu > li.active-menuitem a {
    border-radius: 6px !important;
}
.layout-wrapper
    .layout-sidebar
    .layout-menu
    > li.active-menuitem.open-submenu
    a {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

// fix menu close on mobile mode
.layout-main-mask {
    height: 100vh !important;
}

.layout-breadcrumb {
    padding-top: 0 !important;
}

.topbar-padding {
    padding-top: 64px;
}

//exception panel styling
.layout-content > .exception-body {
    background-image: none !important;
}

.layout-content > .exception-body > .exception-panel {
    position: relative !important;
    top: initial !important;
    left: initial !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: initial !important;
}

.layout-content > .exception-body > .exception-panel > .exception-code {
    background-color: $themeColor !important;
}

.layout-content,
> .exception-body,
> .exception-panel,
> .exception-detail,
> .exception-icon {
    background-color: $themeColor !important;
}

.fc-event,
.fc-list-item {
    cursor: pointer;
}

// fix dialog opaque with primereact 4.0.0 and serenity 3.0.2
.p-dialog-mask {
    opacity: 1 !important;
    background-color: rgba(66, 66, 66, 0.8) !important;
}

.layout-wrapper .layout-sidebar .layout-menu li > a > .menuitem-text {
    max-width: 155px !important;
}

/* Theme overwrites for Unblu Meet rebranding */
.p-card-title {
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 32px !important;
}

.fc-header-toolbar h2 {
    font-weight: 500 !important;
}

div.layout-wrapper .layout-main .layout-topbar {
    color: #5C5F6A;
    background-color: #ffffff;
    box-shadow: none;
    border-bottom: 1px solid #DCDCE0;

    button {
        color: #5C5F6A !important;
        i:not(.adviser-state-color-mobile) {
            color: #5C5F6A !important;
        }
    }
}

body div.p-card {
    box-shadow: none;
}

body div {

    .toolbox-select-button{
        .p-selectbutton {
            .p-button {
                color: #ffffff;
                &.p-highlight {
                    border-color: #ffffff;
                }
                &:not(.p-disabled):not(.p-highlight):hover {
                    color: #ffffff;
                    background-color: #ffffff;

                    .p-button-icon-left {
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .p-selectbutton {
        border-radius: 0;
        //border: 1px solid #C6C7CD;
        .p-button {
            border-top: 1px solid;
            border-bottom: 1px solid;
            border-left: 1px solid;
            border-right: none;
            background-color: #ffffff;
            border-color: #C6C7CD;

            &:first-child {
                border-radius: 4px 0 0 4px;
            }
            &:last-child {
                border-radius: 0 4px 4px 0;
                border-right: 1px solid;
                border-color: #C6C7CD;
            }

            &:not(.p-disabled):not(.p-highlight):hover {
                color: $unbluSecondaryHoverButtonColor;
                background-color: #ffffff;

                .p-button-icon-left {
                    color: $unbluSecondaryHoverButtonColor;
                }
            }
        }
    }
    .p-button {
        box-shadow: none;
        font-weight: 500;
        line-height: 20px;
        font-size: 14px;
        border-radius: 4px;
        margin: 0;
        padding: 5px 12px 5px 12px;
        //height: 20px;

        .p-button-text {
            line-height: 20px;
        }

        &:enabled:not(:focus):not(.p-dataview-header .p-highlight):hover {
            color: $unbluSecondaryHoverButtonColor;
            background-color: #ffffff;

            .p-button-icon-left {
                color: $unbluSecondaryHoverButtonColor;
            }
            &.p-highlight {
                background-color: $unbluPrimaryHoverButtonColor;
                color: #ffffff;
                .p-button-icon-left {
                    color: #ffffff;
                }
            }
        }

        border: 1px solid #C6C7CD;

        color: $unbluSecondaryButtonColor;
        background-color: #ffffff;

        .p-button-icon-left {
            color: $unbluSecondaryButtonColor;
        }

        &.p-button-secondary {
            border: 1px solid #C6C7CD;

            color: $unbluSecondaryButtonColor;
            background-color: #ffffff;

            .p-button-icon-left {
                color: $unbluSecondaryButtonColor;
            }
        }

        &:focus {
            background-color: inherit;
        }

        &.p-highlight {
            background-color: $unbluPrimaryButtonColor;
            border-color: $unbluPrimaryButtonColor;
            color: #ffffff;
            .p-button-icon-left {
                color: #ffffff;
            }
       }
    }
}

body div {
    .p-datatable .p-datatable-header, body div .p-datatable .p-datatable-footer {
        border: none;
        background-color: transparent;

        .p-multiselect-label {
            color: $unbluSecondaryButtonColor;
        }
    }

    .fc {
        .fc-button {
            border: 1px solid #C6C7CD;
            background-color: #ffffff;
            color: $unbluSecondaryButtonColor;
            font-weight: 500;

            &.fc-button-active {
                border-color: #C6C7CD !important;
            }

            &:enabled:not(.fc-button-active):hover {
                color: $unbluSecondaryHoverButtonColor !important;
                background-color: #ffffff !important;
            }
        }
    }

    .layout-wrapper .layout-sidebar {
        box-shadow: none;
    }
}
