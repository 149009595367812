// Settings Panel
.settings-panel {
    max-height: 100%;
    width: 600px;
    overflow: auto;

    .p-dialog-content {
        overflow-y: initial !important;
    }
}

.settings-panel-video {
    height: 200px;
    width: 100%;
    margin-bottom: 20px;

    &.no-video {
        height: 0;
        margin-bottom: 0;
    }

    &.not-mirror-own {
        #al5_videoContainer {
            video {
                transform: none !important;
            }
        }
    }
}

#al5_previewContainer {
    height: 200px !important;
    width: 100%;
}

.settings-audio-level-container {
    border: 1px solid lightgrey;
    height: 16px;
    margin: 4px;
}

.settings-audio-level-bar {
    background-color: $themeColor;
    height: 14px;
}
