/* Theme */
$themeColor: #fa6019 !default;
$themeColorTransparent: rgba($themeColor, 0.5) !default;
$themeColorLight: #fc9869 !default;
$textColor: #5c5f6a !default;

$primaryColor: #26337c !default;
$primaryDarkColor: #404a80 !default;
$primaryLightColor: #90a4ae !default;
$primaryLightestColor: #eceff1 !default;
$primaryTextColor: #ffffff !default;
$accentTextColor: #ffffff !default;

$highlightTableRowColor: rgba(0, 0, 0, 0.3) !default;

/* Sidebar */
$sidebarLogoHeight: 32px !default;
$sidebarLogoMarginTop: 8px !default;
$sidebarLogoMarginLeft: 10px !default;
$sidebarBgColor: #404a80 !default;

/* Topbar */
$topbarMobileLogoHeight: 32px !default;
$topbarMobileLogoMarginTop: 0px !default;

/* Growl */
$successMessageColor: #00cc66 !default;
$infoMessageColor: #006cbd !default;
$errorMessageColor: #ff1432 !default;
$warnMessageColor: #ffc419 !default;
$messageTextColor: black !default;

/* Meeting */
$meetingToolbarBackgroundColor: rgba(22, 38, 55, 0.8) !default;
$meetingToolbarButtonHoverColor: rgba(255, 255, 255, 0.15) !default;
$meetingToolbarButtonToggleColor: rgba(255, 255, 255, 0.2) !default;
$meetingLogoHeight: 35px !default;
$overflowMenuBG: initial !default;
$overflowMenuItemHoverBG: #313d52 !default;
$overflowMenuItemHoverColor: #b8c7e0 !default;
$overflowMenuItemColor: #b8c7e0 !default;
$toolbarButtonColor: #ffffff !default;
$audioLevelBg: #2d99da !default;
