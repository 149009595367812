.reconnecting-spinner-container {
    height: 100px;
    width: 100px;
    margin-top: 50px;
}

.clickable {
    cursor: pointer;
}

.sidebar-logo-img {
    height: $sidebarLogoHeight!important;
    margin-top: $sidebarLogoMarginTop!important;
    margin-left: $sidebarLogoMarginLeft!important;
}

.mobile-logo {
    height: $topbarMobileLogoHeight!important;
    margin-top: $topbarMobileLogoMarginTop!important;
}

.form-input {
    width: 100% !important;

    input {
        width: 100% !important;
    }
}

.dropdown-label-float {
    position: relative;
    top: -42px;
    left: 5px;
    height: 5px;
    display: block;
    color: #607d8b;
    font-size: 12px;
    font-weight: normal;
    pointer-events: none;
}

.error-message {
    color: $errorMessageColor;
    font-size: smaller;
}
