@font-face {
    font-family: 'jitsi';
    src: url('../assets/fonts/jitsi.eot?icrce1');
    src: url('../assets/fonts/jitsi.eot?icrce1#iefix')
            format('embedded-opentype'),
        url('../assets/fonts/jitsi.ttf?icrce1') format('truetype'),
        url('../assets/fonts/jitsi.woff?icrce1') format('woff'),
        url('../assets/fonts/jitsi.svg?icrce1#jitsi') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Source Code Pro - regular */
@font-face {
    font-family: 'Source Code Pro';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/SourceCodePro-Regular.eot'); /* IE9 Compat Modes */
    src: local('Source Code Pro'), local('Source Code Pro Standard'),
        url('../assets/fonts/SourceCodePro-Regular.eot#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../assets/fonts/SourceCodePro-Regular.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../assets/fonts/SourceCodePro-Regular.woff') format('woff'),
        /* Modern Browsers */ url('../assets/fonts/SourceCodePro-Regular.ttf')
            format('truetype'); /* Safari, Android, iOS */
}

[class^='icon-'],
[class*=' icon-'] {
    font-family: 'jitsi';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1.22em;
    font-size: 1.22em;
    cursor: default;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-blur-background:before {
    content: '\e90f';
    color: #a4b8d1;
}
.icon-speaker:before {
    content: '\e92d';
}
.icon-rec:before {
    content: '\e92b';
}
.icon-camera-take-picture:before {
    content: '\e92a';
}
.icon-AUD:before {
    content: '\e900';
}
.icon-HD:before {
    content: '\e927';
}
.icon-LD:before {
    content: '\e928';
}
.icon-SD:before {
    content: '\e929';
}
.icon-gsm-bars:before {
    content: '\e926';
}
.icon-info:before {
    content: '\e922';
}
.icon-mic-camera-combined:before {
    content: '\e903';
}
.icon-feedback:before {
    content: '\e91d';
}
.icon-hangup:before {
    content: '\e905';
}
.icon-chat:before {
    content: '\e906';
}
.icon-share-doc:before {
    content: '\e908';
}
.icon-kick:before {
    content: '\e904';
}
.icon-menu-up:before {
    content: '\e91f';
}
.icon-menu-down:before {
    content: '\e920';
}
.icon-full-screen:before {
    content: '\e90b';
}
.icon-exit-full-screen:before {
    content: '\e90c';
}
.icon-security:before {
    content: '\e90d';
}
.icon-security-locked:before {
    content: '\e90e';
}
.icon-microphone:before {
    content: '\e910';
}
.icon-mic-disabled:before {
    content: '\e912';
}
.icon-raised-hand:before {
    content: '\e91e';
}
.icon-link:before {
    content: '\e913';
}
.icon-shared-video:before {
    content: '\e914';
}
.icon-settings:before {
    content: '\e915';
}
.icon-star:before {
    content: '\e916';
}
.icon-switch-camera:before {
    content: '\e921';
}
.icon-share-desktop:before {
    content: '\e917';
}
.icon-camera:before {
    content: '\e918';
}
.icon-camera-disabled:before {
    content: '\e919';
}
.icon-volume:before {
    content: '\e91a';
}
.icon-presentation:before {
    content: '\e603';
}
.icon-visibility:before {
    content: '\e923';
}
.icon-visibility-off:before {
    content: '\e924';
}
.icon-enlarge:before {
    content: '\e90a';
}
.icon-signal_cellular_0:before {
    content: '\e901';
}
.icon-signal_cellular_1:before {
    content: '\e902';
}
.icon-signal_cellular_2:before {
    content: '\e907';
}
.icon-phone:before {
    content: '\e0cd';
}
.icon-radio_button_unchecked:before {
    content: '\e836';
}
.icon-radio_button_checked:before {
    content: '\e837';
}
.icon-search:before {
    content: '\e8b6';
}
.icon-chat-unread:before {
    content: '\e0b7';
}
.icon-closed_caption:before {
    content: '\e930';
}
.icon-tiles-many:before {
    content: '\e92e';
}
.icon-close:before {
    content: '\e5cd';
}
.icon-open_in_new:before {
    content: '\e89e';
}
.icon-restore:before {
    content: '\e8b3';
}
.icon-navigate_next:before {
    content: '\e409';
}
.icon-menu:before {
    content: '\e5d2';
}
.icon-arrow_back:before {
    content: '\e5c4';
}
.icon-public:before {
    content: '\e80b';
}
.icon-event_note:before {
    content: '\e616';
}
.icon-bluetooth:before {
    content: '\e1aa';
}
.icon-headset:before {
    content: '\e310';
}
.icon-phone-talk:before {
    content: '\e61d';
}
.icon-thumb-menu:before {
    content: '\e5d4';
}
.icon-ninja:before {
    content: '\e909';
}
.icon-invite:before {
    content: '\e145';
}
.icon-add:before {
    content: '\e146';
}
.icon-play:before {
    content: '\f04b';
}
.icon-stop:before {
    content: '\f04d';
}
.icon-dominant-speaker:before {
    content: '\f0a1';
}
