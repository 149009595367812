$controlTileBgColor: #CED2E7;
$controlTileBgColorHover: #b3b9da;
$contentTileBgColor: #DCDCE0;
$contentTileBgColorHover: #c6c7cd;
$customTileBgColor: #B9DDF2;
$customTileBgColorHover: #96ccec;
$tileTextColor: #26337C;

@keyframes growTile {
    to {
        left: 0;
        right: 10px;
    }
}

.adia-pad {
    background-color: #ffffff;
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .tile-container {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 10px;
        padding-right: 10px;

        .tile {
            background-color: $controlTileBgColor;
            width: 100px;
            border-radius: 5px;
            margin: 10px 0 0 10px;
            text-align: center;
            height: 100px;
            color: $tileTextColor;
            position: relative;
            cursor: pointer;
            user-select: none;
            font-weight: 500;
            font-size: 12px;

            &.disabled {
                opacity: 0.5;
                cursor: auto;
            }

            &.content {
                background-color: $contentTileBgColor;

                &:not(.disabled):hover {
                    background-color: $contentTileBgColorHover;
                    box-shadow: 0 0 3px $contentTileBgColor;
                }
            }

            &.custom {
                background-color: $customTileBgColor;

                &:not(.disabled):hover {
                    background-color: $customTileBgColorHover;
                    box-shadow: 0 0 3px $customTileBgColor;
                }
            }

            .tile-icon {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 66px;

                svg {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 100%;
                    transform: translateY(-50%);

                    max-height: 30px;
                    fill: $tileTextColor;
                }
            }

            .tile-title {
                position: absolute;
                //background-color:#f00;
                height: 45px;
                bottom: 0;
                width: 100%;
                padding-left: 3px;
                padding-right: 3px;
                overflow: hidden;
            }

            &.separator {
                width: 100%;
                height: 20px;
                margin: 0;
                background-color: transparent;
                cursor: auto;
                opacity: 0;
            }

            &:not(.disabled):hover {
                background-color: $controlTileBgColorHover;
                box-shadow: 0 0 3px $controlTileBgColor;
            }
        }
    }
}
