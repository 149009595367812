.edit-account-dialog {
    width: 800px;
    max-width: 100%;
}

.p-accordion {
    .p-accordion-tab:not(:last-child) {
        padding-bottom: 8px;
    }
}
