.app-loading {
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
}

.circle-container {
    width: 80px;
    height: 80px;
}

.circle-inner {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 8px solid #fa6019;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    margin: 8px;
    -webkit-animation: spinInner 3.1s ease infinite;
    -moz-animation: spinInner 3.1s ease infinite;
    animation: spinInner 3.1s ease infinite;
    box-sizing: content-box;
}

.circle-outer {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 8px solid #404a80;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    -webkit-animation: spinOuter 2.4s ease;
    -moz-animation: spinOuter 2.4s ease infinite;
    animation: spinOuter 2.4s ease infinite;
    box-sizing: content-box;
}

@-moz-keyframes spinOuter {
    40% {
        -moz-transform: rotate(-360deg);
    }

    100% {
        -moz-transform: rotate(180deg);
    }
}

@-webkit-keyframes spinOuter {
    40% {
        -webkit-transform: rotate(-360deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
    }
}

@keyframes spinOuter {
    40% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

@-moz-keyframes spinInner {
    40% {
        -moz-transform: rotate(360deg);
    }

    100% {
        -moz-transform: rotate(-18deg);
    }
}

@-webkit-keyframes spinInner {
    40% {
        -webkit-transform: rotate(360deg);
    }

    100% {
        -webkit-transform: rotate(-18deg);
    }
}

@keyframes spinInner {
    40% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }

    100% {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }
}
