.login-panel-w-captcha {
    /*position: absolute !important;
  top: 380px !important;*/
    height: 580px !important;
    margin-top: -340px !important;
}

.login-panel-header {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: unset;

    img {
        max-height: 30px;
        width: unset !important;
    }
}
