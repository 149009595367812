$videoListWidth: 240px;
$videoListWidthMedium: 160px;
$meetingToolbarSize: 40px;
$meetingToolbarVisibleSpace: 60px;
$toolbarBackgroundZ: 4;
$toolbarZ: 350;

$chatWidth: 450px;
$meetingBgColor: #d3d3d3;
$toolBarBgColor: #363636;

.meeting-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    .meeting-panel-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;

        &.side-panel-active {
            right: $chatWidth;
        }

        &.mobile {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 60%;

            &.side-panel-active {
                right: 0;
            }
        }
    }

    .side-panel-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: $chatWidth;
        background-color: #696969;

        &.hidden {
            display: none;
        }

        &.overlayed {
            width: 100%;
        }

        &.mobile {
            top: auto;
            bottom: 0;
            left: 0;
            right: 0;
            height: 40%;
            width: 100%;

            &.side-panel-active {
                width: 100%;
            }
        }
    }

    // special side panel icons
    .p-menuitem:hover {
        .side-panel-icon {
            background-color: #000000;
        }
        .showBadge {
            color: $themeColor !important;
            &.side-panel-icon {
                background-color: $themeColor !important;
            }
        }
    }
    .p-menuitem .showBadge {
        color: $themeColor !important;

        &.side-panel-icon {
            background-color: $themeColor !important;
        }
    }

    .side-panel-menu-active a {
        background-color: #e8e8e8;
        color: #000000;

        .side-panel-icon {
            background-color: #000000;
        }
    }
    .side-panel-icon {
        display: inline-block;
        width: 17px;
        height: 18px;
        background-color: #757575;
        margin-left: 1px;
        mask-repeat: no-repeat;
        mask-position: center center;

        &.pad {
            mask-image: url(../../assets/icons/icon_pad.svg);
        }

        &.users {
            mask-image: url(../../assets/icons/icon_users.svg);
        }
    }

    // css from meeting.scss
    .meeting {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $meetingBgColor;
        overflow: hidden;
    }

    .meeting-main-video {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &.controlsVisible {
            height: calc(100% - 60px);
        }
        /*position: absolute;
    height: 100vh;
    width: 100vw;*/
    }

    .video-container {
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .main-video-placeholder-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        color: rgba(255, 255, 255, 0.75);
        font-size: 150px;

        .name-initial {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.75);
            font-size: 60px;
            line-height: 150px;
            text-align: center;
            vertical-align: middle;
            color: rgba(0, 0, 0, 0.8);
            font-weight: bold;
            text-transform: uppercase;
            user-select: none;

            //box-shadow: 0 0 10px 0 #44A5FF;
        }
    }

    .participant-list {
        position: absolute;
        top: 10px;
        right: 10px;
        width: $videoListWidth;
        //width: calc((#{$videoListWidth} + 10));
        //height: 100vh;
        bottom: 10px;
        transition: bottom 0.3s ease-in;
        z-index: 9200;

        &.controlsVisible {
            bottom: $meetingToolbarVisibleSpace + 10px;
        }

        .remote-videos {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 146px;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .local-video {
            position: absolute;
            bottom: 0;
            width: 100%;
            overflow: hidden;

            .video-list-box {
                margin-top: 0;
            }
        }

        .video-list-box {
            position: relative;
            height: 135px;
            margin-top: 10px;
            border: rgba(0, 0, 0, 0) 2px solid;
            overflow: hidden;
            border-radius: 6px;
            width: $videoListWidth;
            //box-sizing: content-box;
            background-color: rgba(0, 0, 0, 0.6);
            //box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);

            &:first-child {
                margin-top: 0;
            }

            &.video-box-active {
                //border-color: green !important;
                border: green 2px solid;
            }

            &:hover {
                .connection-indicator {
                    display: block !important;
                }

                .visibility-icon {
                    display: block !important;
                }
            }

            > div {
                box-sizing: border-box;
                position: absolute;
                top: 0;
            }

            .video-container {
                position: absolute;
                height: 135px;
                width: $videoListWidth;
                top: 0;
                left: 50%;
                transform: translateX(-50%);

                > div:not(.video-list-icon),
                video {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }

            .participant-name-wrapper {
                z-index: 10;
                top: 0;
                width: 100%;
                left: 0;
                height: 30px;
                overflow: hidden;
                background-image: linear-gradient(
                    rgba(0, 0, 0, 0.6),
                    rgba(0, 0, 0, 0)
                );

                .participant-name {
                    position: absolute;
                    color: #fff;
                    line-height: 16px;
                    vertical-align: middle;
                    left: 5px;
                    height: 16px;
                    top: 3px;
                }
            }

            .muted-icon {
                right: 5px;
                color: #fff;
                top: 2px;
                z-index: 11;

                i {
                    font-size: 14px;
                    line-height: 16px;
                    vertical-align: middle;
                }
            }

            .visibility-icon {
                display: none;
                top: inherit;
                bottom: 4px;
                left: 7px;
                color: #fff;
                z-index: 11;

                &.show-always {
                    display: block;
                }

                i {
                    text-shadow: -3px 3px 20px #000000;
                    cursor: pointer;
                    font-size: 22px;
                    line-height: 22px;
                    vertical-align: middle;
                }
            }

            .connection-indicator {
                top: inherit;
                bottom: 0;
                right: 5px;
            }

            .revoke-button {
                top: inherit;
                position: absolute;
                bottom: 5px;
                width: 20px;
                height: 20px;
                left: calc(50% - 10px);
                padding: 0 5px;

                .p-button-icon-left {
                    margin-top: -9px;
                    margin-bottom: -9px;
                    width: 18px;
                    height: 18px;
                    font-size: 18px;
                }
            }

            .audio-level-indicator {
                position: absolute;
                right: 8px;
                top: 50%;
                margin-top: -20px;
                width: 6px;
                //height: 35px;
                border: none;

                .audiodot {
                    opacity: 0;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: $audioLevelBg;
                    margin: 4px 0 4px 0;
                    //transition: opacity .25s ease-in-out;
                    //-moz-transition: opacity .25s ease-in-out;
                }
            }

            .video-list-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                color: rgba(255, 255, 255, 0.75);
                font-size: 75px;
                line-height: 75px;

                .name-initial {
                    width: 75px;
                    height: 75px;
                    border-radius: 50%;
                    background: rgba(255, 255, 255, 0.75);
                    font-size: 30px;
                    line-height: 75px;
                    text-align: center;
                    vertical-align: middle;
                    color: rgba(0, 0, 0, 0.8);
                    font-weight: bold;
                    text-transform: uppercase;
                    user-select: none;
                }
            }

            .connection-indicator {
                display: none;
                position: absolute;
                z-index: 9001;
                padding: 3px;
                margin: 3px;
                font-size: 12px;

                &.connection-good {
                    color: green;
                }

                &.connection-medium {
                    color: orange;
                }

                &.connection-bad {
                    color: red;
                }

                &.show-always {
                    display: block;
                }
            }
        }
    }

    .meeting-wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
    }

    .meeting {
        &.responsive-mode-medium {
            .participant-list {
                width: $videoListWidthMedium;

                .video-list-box {
                    width: $videoListWidthMedium;
                    height: 90px;
                }

                .video-container {
                    position: absolute;
                    width: $videoListWidthMedium;
                    height: 90px;
                }

                .name-initial {
                    width: 50px !important;
                    height: 50px !important;
                    font-size: 20px !important;
                    line-height: 50px !important;
                }

                .video-list-icon {
                    font-size: 50px !important;
                    line-height: 50px !important;
                }

                .remote-videos {
                    bottom: 100px;
                }
            }

            .meeting-main-video.screenshare {
                width: calc(100% - #{$videoListWidthMedium} - 30px);
                padding-left: 10px;
            }

            /*.meeting-toolbar {
                .toolbox-content {
                    .toolbox-button {
                        margin: 0 2px;
                    }
                }
            }*/
        }

        &.responsive-mode-small {
            .meeting-main-video.screenshare {
                width: calc(100% - 20px);
                padding-left: 10px;
            }

            /*.meeting-toolbar {
                .toolbox-content {
                    .toolbox-button {
                        margin: 0 6px;
                    }
                }
            }*/
        }

        .meeting-main-video.screenshare {
            width: calc(100% - #{$videoListWidth} - 30px);
            padding-left: 10px;
        }
    }

    .stats-table {
        .td {
            text-align: left;
        }
    }

    // copy from jitsi-meet with small modifications ;)

    .meeting-topbar {
        top: calc((#{$meetingToolbarSize} * 2) * -1);
        left: 0;
        position: absolute;
        right: 0;
        transition: top 0.3s ease-in;
        width: 100%;

        &.visible {
            top: 0;

            .meeting-topbar-background {
                top: 0;
            }
        }

        .meeting-topbar-background {
            /*background-image: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0)
            );*/
            transition: top 0.3s ease-in;
            height: 160px;
            width: 100%;
            top: -160px;
            pointer-events: none;
            position: absolute;
            z-index: $toolbarBackgroundZ;
        }

        .meeting-topbar-content {
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            margin-left: auto;
            margin-right: auto;
            padding: 20px 16px;
            position: relative;
            width: 100%;
            z-index: $toolbarZ;
            color: #fff;
            //font-size: 20px;

            .serviceLogo {
                height: $meetingLogoHeight;
            }

            .button-group-center {
                display: flex;
                width: 33%;
                height: 25px;
                margin-top: 5px;

                &.time-active {
                    .topbar-title {
                        border-radius: 5px 0 0 5px;
                    }
                    .timeInfo {
                        border-radius: 0 5px 5px 0;
                        border-left: 1px solid #666666;
                    }
                }

                .topbar-title,
                .timeInfo {
                    font-size: 16px;
                    line-height: 25px;
                    vertical-align: middle;
                    display: inline-block;
                    padding: 0 10px;
                    border-radius: 5px;
                    background-color: $toolBarBgColor;

                    &.timeInfo {
                        font-size: 15px;
                        font-family: 'Source Code Pro', monospace;
                    }
                }
            }

            .button-group-left,
            .button-group-right {
                display: flex;
                width: 33%;
            }

            .button-group-half {
                display: flex;
                width: 50%;
            }
            .button-group-half-right {
                display: flex;
                width: 50%;
                justify-content: flex-end;
            }

            .button-group-center {
                justify-content: center;

                /*.toolbox-icon {
                    margin: 0 4px;
                }*/
            }

            .button-group-right {
                justify-content: flex-end;
            }
        }
    }

    .meeting-toolbar,
    .side-panel-tab-bar {
        bottom: calc((#{$meetingToolbarSize} * 2) * -1);
        left: 0;
        position: absolute;
        right: 0;
        transition: bottom 0.3s ease-in;
        width: 100%;
        pointer-events: none;

        &.visible {
            bottom: 0;

            .toolbox-background {
                bottom: 0;
            }
        }

        &.no-buttons {
            display: none;
        }

        .toolbox-background {
            /*background-image: linear-gradient(
                to top,
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0)
            );*/
            transition: bottom 0.3s ease-in;
            height: 160px;
            width: 100%;
            bottom: -160px;
            position: absolute;
            z-index: 9100;
        }

        .toolbox-content {
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            margin-left: auto;
            margin-right: auto;
            padding: 10px 10px;
            position: relative;
            width: 100%;
            z-index: 9500;
            background-color: $toolBarBgColor;

            .title-time {
                color: #fff;
                font-size: 15px;
                height: 40px;
                display: flex;
                align-items: center;
            }

            .button-group-left,
            .button-group-right {
                display: flex;
                width: 30%;
            }

            .button-group-full-center {
                display: flex;
                width: 100%;
                justify-content: center;

                /*.toolbox-icon {
                    margin: 0 4px;
                }*/
            }

            .button-group-full-right {
                display: flex;
                width: 100%;
                justify-content: flex-end;
            }

            .button-group-center {
                display: flex;
                width: 40%;
                justify-content: center;

                /*.toolbox-icon {
                    margin: 0 4px;
                }*/
            }

            .button-group-half {
                display: flex;
                width: 50%;
            }
            .button-group-half-right {
                display: flex;
                width: 50%;
                justify-content: flex-end;
            }

            .button-group-right {
                justify-content: flex-end;
            }

            .button-group-side-panel-bar {
                display: flex;
                flex: 1 0 auto;
                width: $chatWidth;
                justify-content: flex-end;
            }

            i,
            .svg-icon {
                border-radius: 5px;
                cursor: pointer;
                pointer-events: auto;
                display: block;
                font-size: inherit;
                height: 100%;
                line-height: inherit;
                width: 100%;
            }

            .svg-icon {
                position: relative;

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-height: 20px;
                    max-width: 20px;
                    fill: #ffffff;
                }
            }

            i:hover,
            .svg-icon:hover {
                background: $meetingToolbarButtonHoverColor;
            }

            i.toggled,
            .svg-icon.toggled {
                background: $meetingToolbarButtonToggleColor;
            }

            i.toggled:hover,
            .svg-icon.toggled:hover {
                background: $meetingToolbarButtonHoverColor;
            }

            .icon-hangup {
                background-color: #e12d2d;
                color: #fff;
                /*border-radius: 50%;
                width: 40px;
                height: 40px;*/

                &:hover {
                    background-color: #e54b4b;
                }
            }

            i.disabled,
            .svg-icon.disabled,
            .disabled i {
                cursor: initial !important;
                color: #fff !important;
                background-color: #a4b8d1 !important;
            }

            /*.icon-mic-disabled,
            .icon-microphone,
            .icon-camera-disabled,
            .icon-camera {
                background-color: #fff;
                color: #5e6d7a;
                border-radius: 50%;
                border: 1px solid #d1dbe8;
                width: 38px;
                height: 38px;

                &:hover {
                    background-color: #daebfa;
                    border: 1px solid #daebfa;
                }

                &.toggled {
                    background: #2a3a4b;
                    color: #fff;
                    border: 1px solid #5e6d7a;

                    &:hover {
                        background-color: #5e6d7a;
                    }
                }

                &.disabled,
                .disabled & {
                    cursor: initial;
                    color: #fff;
                    background-color: #a4b8d1;
                }
            }*/

            .toolbox-select-button {
                pointer-events: auto;
                padding-top: 5px;
                white-space: nowrap;

                .p-selectbutton {
                    .p-button {
                        padding-top: 7px;
                        pointer-events: auto;
                        background-color: $meetingToolbarButtonToggleColor;

                        &.p-highlight {
                            background-color: #fff;

                            svg {
                                color: #5e6d7a;
                            }
                        }
                    }
                    .p-button:not(.p-disabled):not(.p-highlight):hover {
                        background-color: rgba(1, 1, 1, 0.4);
                    }
                }
                .p-buttonset {
                    min-width: 132px;
                }
            }

            .overflow-menu {
                font-size: 1.2em;
                list-style-type: none;
                background-color: $overflowMenuBG;
                /**
   * Undo atlaskit padding by reducing margins.
   */
                margin: -16px -24px;
                padding: 4px 0;

                .overflow-menu-item {
                    align-items: center;
                    color: $overflowMenuItemColor;
                    cursor: pointer;
                    display: flex;
                    font-size: 14px;
                    height: 22px;
                    padding: 5px 12px;

                    div {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }

                    &:hover {
                        background-color: $overflowMenuItemHoverBG;
                        color: $overflowMenuItemHoverColor;
                    }

                    &.unclickable {
                        cursor: default;
                    }

                    &.unclickable:hover {
                        background: inherit;
                    }

                    &.disabled {
                        cursor: initial;
                        color: #3b475c;
                    }

                    i.toggled {
                        background: inherit;
                    }

                    i.toggled:hover {
                        background: inherit;
                    }
                }

                .beta-tag {
                    background: $overflowMenuItemColor;
                    border-radius: 2px;
                    color: $overflowMenuBG;
                    font-size: 11px;
                    font-weight: bold;
                    margin-left: 8px;
                    padding: 0 6px;
                }

                .overflow-menu-item-icon {
                    margin-right: 10px;

                    i {
                        display: inline;
                        font-size: 24px;
                    }

                    i:hover {
                        background-color: initial;
                    }

                    img {
                        max-width: 24px;
                        max-height: 24px;
                    }
                }

                .profile-text {
                    max-width: 150px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            .toolbox-button {
                color: $toolbarButtonColor;
                cursor: pointer;
                display: inline-block;
                line-height: $meetingToolbarSize;
                margin: 0 8px;
                text-align: center;
            }

            .toolbar-button-with-badge {
                position: relative;

                .badge-round {
                    bottom: -5px;
                    font-size: 12px;
                    line-height: 20px;
                    min-width: 20px;
                    pointer-events: none;
                    position: absolute;
                    right: -5px;
                }
            }

            .toolbox-button-wth-dialog {
                display: inline-block;
            }

            .toolbox-icon {
                height: $meetingToolbarSize;
                font-size: 24px;
                width: $meetingToolbarSize;
            }
        }

        &.side-panel-bar-active {
            .toolbox-menu-container {
                right: calc(#{$chatWidth} + 20px);
            }
        }
    }

    .toolbox-menu-container {
        position: absolute;
        right: 20px;
        bottom: 70px;
        z-index: 9300;
        pointer-events: auto;

        .p-menu {
            min-width: 14.5em;
        }
    }

    .toolbox-info-panel {
        position: absolute;
        bottom: 70px;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, 0);
        z-index: 9300;
        pointer-events: auto;
    }

    .toolbox-badge {
        position: relative;

        .badge-round {
            bottom: 5px;
            right: -20px;
            font-size: 12px;
            line-height: 16px;
            min-width: 16px;
            pointer-events: none;
            position: absolute;
            background-color: $themeColor;
            border-radius: 16px;
        }
    }

    .toolbox-icon > i {
        outline: none;
    }

    .copy-icon {
        padding: 2px;
        border-radius: 5px;
    }

    h1 .copy-icon {
        font-size: 21px;
    }

    .copy-icon:hover {
        background-color: lightgrey;
    }

    .recording-on,
    .stream-offline > .p-menuitem-link > .p-menuitem-icon {
        color: red !important;
    }

    .stream-online > .p-menuitem-link > .p-menuitem-icon {
        color: green !important;
    }

    .item-pending > .p-menuitem-link > .p-menuitem-icon {
        color: $themeColor !important;
    }

    .meeting-main-video {
        .combi-container {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .combi-container {
        position: absolute;
        width: 100%;
        padding-bottom: 56.25%;

        .combi-video {
            position: absolute;
            height: 100%;
            width: 100%;

            .fm-video-local {
                transform: scaleX(1) !important;
            }

            .video-container {
                height: 100% !important;
                width: 100% !important;
            }
        }
        .combi-screen {
            position: absolute;
            width: 51%;
            height: 51%;
            left: 47%;
            top: 19%;

            .video-container {
                height: 100% !important;
                width: 100% !important;
            }
        }
    }

    &.not-mirror-own {
        #al5_videoContainer {
            video {
                transform: none !important;
            }
        }
    }

    .meeting-indicators-container {
        position: absolute;
        top: 15px;
        left: 15px;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        font-size: 18px;
        transition: top 0.3s ease-in;

        &.controls-visible {
            top: 80px;
        }

        .onair-state-indicator {
            .onair-state-icon {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 145px;
                height: 30px;
                padding: 5px;

                border-radius: 10px;
                font-weight: bold;
                color: white;
                font-size: 12px;

                &.offline {
                    background-color: grey;
                }

                &.online {
                    background-color: red;
                }
            }
        }

        .recording-indicator {
            margin-left: 0.5em;
            color: red;

            &:first-child {
                margin-left: 0;
            }

            &.blink {
                animation: blinker 3.5s cubic-bezier(0.8, 0, 1, 1) infinite
                    alternate;
            }

            @keyframes blinker {
                from {
                    opacity: 1;
                }
                to {
                    opacity: 0.2;
                }
            }
        }
    }

    // Shared Applications Panel
    .meeting {
        &.videolist-active {
            &.responsive-mode-medium {
                .sa-panel-container {
                    right: calc(#{$videoListWidthMedium} + 20px);
                }
            }

            .sa-panel-container {
                right: calc(#{$videoListWidth} + 20px);
            }
        }
    }

    .sa-panel-container {
        position: absolute;
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
        z-index: 9000;

        &.hidden {
            display: none;
        }

        &.controlsVisible {
            bottom: 70px;
        }

        &.standalone {
            right: 10px;
            bottom: 10px;
        }

        .sa-panel {
            position: absolute;
            //top: 10px;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;

            .sa-panel-header {
                position: relative;

                .p-tabmenu-nav {
                    position: relative;
                }

                .sa-panel-controls {
                    position: absolute;
                    right: 10px;
                    top: 45%;
                    transform: translateY(-50%);
                    z-index: 9001;
                }
            }

            .sa-panel-body {
                position: relative;
                padding-bottom: 56.25%;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    border: none;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .active-sa-tab {
        background-color: $themeColor !important;
    }

    .panel-close-button {
        position: absolute;
        top: 5px;
        right: 5px;
    }

    .close-icon:hover {
        background-color: lightgrey;
        border-radius: 10px;
    }

    .close-icon.disabled {
        color: lightgrey;
    }

    .edit-mode-select {
        margin: 0 10px;
        top: 5px;
        i {
            font-size: 16px;
        }
    }

    // Stream Chat Panel

    .side-panel {
        position: relative;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        padding: 0;
        opacity: 1;

        z-index: 9001;

        &.darkBackground {
            /*.side-panel-header {
                background-color: #696969;
                color:#ffffff;
            }*/
        }

        .side-panel-header {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 31px;
            padding-left: 10px;
            padding-top: 6px;
            font-size: 18px;
            background-color: #ffffff;
            z-index: 11;

            .btnClose {
                position: absolute;
                right: 10px;
                top: 2px;
                cursor: pointer;
                i {
                    cursor: pointer;
                }
            }
        }

        .side-panel-iframe-container {
            position: absolute;
            top: 30px;
            left: 0;
            width: 100%;
            bottom: 60px;
            z-index: 10;
            background-color: #fff;

            .side-panel-iframe {
                height: 100%;
                width: 100%;
                padding: 0;
                border: none;
            }
        }

        .side-panel-tab-bar {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        .hidden,
        &.hidden {
            display: none;
        }
    }

    .participant-actions-dialog {
        max-height: 100%;
        max-width: 100%;
        overflow: auto;
    }

    .add-adviser-dialog {
        max-height: 100%;
        max-width: 100%;
        overflow: auto;

        .state-accepted {
            color: $successMessageColor;
        }
        .state-pending {
            color: $warnMessageColor;
        }
        .state-canceled {
            color: $errorMessageColor;
        }

        .comment-output {
            max-width: 250px;
            min-width: 200px;
            color: #bbbbbb;
            font-style: italic;
        }
    }

    .recording-confirmation-dialog {
        max-height: 100%;
        max-width: 100%;
        width: 600px;
        overflow: auto;
    }

    .transfer-call-dialog {
        .p-dialog-content {
            overflow: visible !important;
        }
    }

    .circle-container-dialog {
        margin-left: -4px;

        .circle-inner {
            height: 10px;
            width: 10px;
            margin: 11px;
            border: 3px solid #ff9800;
            border-top: 3px solid transparent;
            border-bottom: 3px solid transparent;
        }

        .circle-outer {
            height: 16px;
            width: 16px;
            margin: 8px;
            border: 3px solid #607d8b;
            border-top: 3px solid transparent;
            border-bottom: 3px solid transparent;
        }
    }

    .participant-tab {
        height: 100%;
    }

    .participant-tab-scroll-panel {
        height: 100%;
        width: 100%;
        padding-right: 10px;
        border: none !important;

        .p-scrollpanel-wrapper {
            padding-bottom: 10px;
        }
    }

    .participant-requesting-panel {
        margin: 10px 0 10px 10px;
    }

    .participant-list-panel {
        margin: 10px 0 0 10px;
    }

    .tab-menu-badge {
        position: relative;

        .badge-round {
            bottom: 7px;
            right: -14px;
            font-size: 12px;
            line-height: 16px;
            min-width: 16px;
            pointer-events: none;
            position: absolute;
            background-color: $themeColor;
            border-radius: 16px;
            text-align: center;
            color: white;
        }
    }
}

#al5_videoContainer {
    height: 100%;
    width: 100%;
    position: relative;

    > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        transform: none !important; // chrome 85 hotfix
    }

    video {
        position: absolute;
        width: 100%;
        height: 100%;

        transform: scaleX(-1) !important; // chrome 85 hotfix
    }
}

.fm-video,
.jitsi-video {
    position: absolute;
    width: 100%;
    height: 100%;
}
